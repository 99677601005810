import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";

import {
    JwtToken,
    Cache,
    FullTable,
    ColumnType,
    Column,
    PaginateAdvancedTable,
    ButtonGroup,
} from "@boomrank/react-components";
import {Paths} from "@boomrank/react-components/src/dummies/table/v2/models";

import {
    Link as Backlink,
    Account,
    Customer,
    Provider,
    Quote,
    LastType,
    Identifier
} from "../../../../models";

import {StandaloneLink} from "../../../../models/standalone";
import {StandaloneLinkFactory} from "../../../../services/link/factory";
import {ExportApi} from "../../../../services/export/api";

import classNames from "classnames";
import {MainLinkCard} from "../../../dummies/link/cards";
import {defaultLanguage, getTranslation, translator} from "../../../../intl";
import {CheckAnalysisLinks} from "./check-analysis";
import {LinkBulkOperations} from "./bulk";


interface Props {
    token: JwtToken,
    links: Backlink[]
    columns: (keyof StandaloneLink | string)[]
    cacheAccounts: Cache<Account>
    cacheCustomers: Cache<Customer>
    cacheProviders: Cache<Provider>
    cacheQuotes: Cache<Quote>
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
    onToggleValid?: (state: 'all' | 'valid' | 'issues' | 'broken' | 'underCorrection') => void
    previousPathContent?: string
}

export function LinkTableView(props: Props) {
    let location = useLocation()

    let [rows, setRows] = useState<StandaloneLink[]>([])
    let [showColumns, setShowColumns] = useState<(Paths<StandaloneLink>)[]>([])

    let [selectedRows, setSelectedRows] = useState<StandaloneLink[]>([])
    let [resetRows, setResetRows] = useState<boolean>(false)

    let [toggleState, setToggleState] = useState<'all' | 'valid' | 'issues' | 'broken' | 'underCorrection'>('all')

    let defaultColumns: Column<StandaloneLink>[] = [
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.KEY'),
            type: ColumnType.STRING,
            accessor: "key",
            cell: (row: StandaloneLink, value: any) => {
                if (props.previousPathContent) {
                    if (props.previousPathContent === 'customer') {
                        return (
                            <Link
                                to={`/dashboard/netlinking/link/${row.id}/`}
                                state={{
                                    previousPath: location.pathname,
                                    previousPathContent: row.customerName
                                }}
                                className={'btn-blue no-underline p-1 px-2 rounded-sm text-md'}
                            >
                                Détail
                            </Link>
                        )
                    }

                    if (props.previousPathContent === 'quote') {
                        return (
                            <Link
                                to={`/dashboard/netlinking/link/${row.id}/`}
                                state={{
                                    previousPath: location.pathname,
                                    previousPathContent: row.quoteRef!
                                }}
                                className={'btn-blue no-underline p-1 px-2 rounded-sm text-md'}
                            >
                                Détail
                            </Link>
                        )
                    }
                }

                return (
                    <Link
                        to={`/dashboard/netlinking/link/${row.id}/`}
                        className={'btn-blue no-underline p-1 px-2 rounded-sm text-md'}
                    >
                        Détail
                    </Link>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.CREATED_AT'),
            type: ColumnType.DATE,
            accessor: 'createdAt',
            cell: (row: StandaloneLink) => {
                return row.createdAt.toLocaleDateString(
                    defaultLanguage,
                    {day: '2-digit', month: '2-digit', year: 'numeric'}
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.PERIOD'),
            type: ColumnType.STRING,
            accessor: 'period',
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.LAST_UPDATE'),
            type: ColumnType.DATE,
            accessor: 'lastUpdate',
            cell: (row: StandaloneLink) => {
                if (row.lastUpdate) {
                    return row.lastUpdate.toLocaleDateString(
                        defaultLanguage,
                        {day: '2-digit', month: '2-digit', year: 'numeric'}
                    )
                }
                return '-'
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.USER_EMAIL'),
            type: ColumnType.STRING,
            accessor: 'userEmail',
            cell: (row: StandaloneLink, value: any) => {
                return (
                    <div title={value}
                         className={'overflow-hidden whitespace-nowrap cursor-help underline decoration-dashed'}>
                        {value}
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.IS_VALID'),
            type: ColumnType.NUMBER,
            accessor: 'validState',
            cell: (row: StandaloneLink, value: any) => {
                let iconName = 'fa-circle-question text-gray-500'
                let title = 'Etat du lien inconnu...'

                if (value === 4) {
                    iconName = 'fa-hourglass-half text-orange-400'
                    title = "Le lien est en attente d'un retour éditeur"
                }
                if (value === 3) {
                    iconName = 'fa-circle-xmark text-red-400'
                    title = 'Le lien est marqué comme définitivement cassé'
                }
                if (value === 2) {
                    iconName = 'fa-triangle-exclamation text-yellow-400'
                    title = 'Le lien semble problématique'
                }
                if (value === 1) {
                    iconName = 'fa-circle-check text-green-500'
                    title = 'Le lien est valide (crawl)'
                }
                if (value === 0) {
                    iconName = 'fa-thumbs-up text-cyan-400'
                    title = "Le lien est valide (manuellement)"
                }

                return (
                    <div className={''}>
                        <i className={`fa-solid ${iconName}`}
                           title={title}
                        />
                    </div>
                )
            },
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.IS_INDEXED'),
            type: ColumnType.BOOLEAN,
            accessor: 'isIndexed',
            cell: (row: StandaloneLink, value: any) => {
                return (
                    <div className={''}>
                        {
                            value ?
                                <i className="fa-solid fa-check-circle text-green-500"
                                   title={'Indexé'}
                                /> :
                                value === null ?
                                    <i className="fa-solid fa-lock"
                                       title={'Désactivé pour le moment'}
                                    />
                                :
                                    <i className="fa-solid fa-times-circle text-red-500"
                                       title={'Non indexé'}
                                    />
                        }
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.LAST_META'),
            type: ColumnType.STRING,
            accessor: 'lastMeta',
            cell: (row: StandaloneLink) => {
                return (
                    <div className={classNames(
                        'font-bold',
                        {
                            'text-green-500': row.metaValid,
                            'text-red-500': !row.metaValid,
                        }
                    )}>
                        {row.lastMeta}
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.META_VALID'),
            type: ColumnType.BOOLEAN,
            accessor: 'metaValid',
            cell: (row: StandaloneLink, value: any) => {
                return (
                    <div className={''}>
                        {
                            value ?
                                <i className="fa-solid fa-check-circle text-green-500"
                                   title={'Valide'}
                                /> :
                                <i className="fa-solid fa-times-circle text-red-500"
                                   title={'Invalide'}
                                />
                        }
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.LAST_CONTENT'),
            type: ColumnType.STRING,
            accessor: 'lastContent',
            cell: (row: StandaloneLink) => {
                return (
                    <div className={classNames(
                        'font-bold',
                        {
                            'text-green-500': row.contentValid,
                            'text-red-500': !row.contentValid,
                        }
                    )}>
                        {row.lastContent}
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.CONTENT_VALID'),
            type: ColumnType.BOOLEAN,
            accessor: 'contentValid',
            cell: (row: StandaloneLink, value: any) => {
                return (
                    <div className={''}>
                        {
                            value ?
                                <i className="fa-solid fa-check-circle text-green-500"
                                   title={'Valide'}
                                /> :
                                <i className="fa-solid fa-times-circle text-red-500"
                                   title={'Invalide'}
                                />
                        }
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.LAST_REL'),
            type: ColumnType.STRING,
            accessor: 'lastRel',
            cell: (row: StandaloneLink) => {
                return (
                    <div className={classNames(
                        'font-bold',
                        {
                            'text-green-500': row.relValid,
                            'text-red-500': !row.relValid,
                        }
                    )}>
                        {row.lastRel}
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.REL_VALID'),
            type: ColumnType.BOOLEAN,
            accessor: 'relValid',
            cell: (row: StandaloneLink, value: any) => {
                return (
                    <div className={''}>
                        {
                            value ?
                                <i className="fa-solid fa-check-circle text-green-500"
                                   title={'Valide'}
                                /> :
                                <i className="fa-solid fa-times-circle text-red-500"
                                   title={'Invalide'}
                                />
                        }
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.LAST_HEADERS'),
            type: ColumnType.STRING,
            accessor: 'lastHeaders',
            cell: (row: StandaloneLink) => {
                return (
                    <div className={classNames(
                        'font-bold',
                        {
                            'text-green-500': row.headersValid,
                            'text-red-500': !row.headersValid,
                        }
                    )}>
                        {row.lastHeaders}
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.HEADERS_VALID'),
            type: ColumnType.BOOLEAN,
            accessor: 'headersValid',
            cell: (row: StandaloneLink, value: any) => {
                return (
                    <div className={''}>
                        {
                            value ?
                                <i className="fa-solid fa-check-circle text-green-500"
                                   title={'Valide'}
                                /> :
                                <i className="fa-solid fa-times-circle text-red-500"
                                   title={'Invalide'}
                                />
                        }
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.NB_REDIRECT'),
            type: ColumnType.NUMBER,
            accessor: 'nbRedirect',
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.LAST_HTTP_CODE'),
            type: ColumnType.STRING,
            accessor: 'lastHttpCode',
            cell: (row: StandaloneLink) => {
                return (
                    <div className={classNames(
                        'font-bold',
                        {
                            'text-green-500': row.httpCodeValid,
                            'text-red-500': !row.httpCodeValid,
                        }
                    )}>
                        {row.lastHttpCode}
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.HTTP_CODE_VALID'),
            type: ColumnType.BOOLEAN,
            accessor: 'httpCodeValid',
            cell: (row: StandaloneLink, value: any) => {
                return (
                    <div className={''}>
                        {
                            value ?
                                <i className="fa-solid fa-check-circle text-green-500"
                                   title={'Valide'}
                                /> :
                                <i className="fa-solid fa-times-circle text-red-500"
                                   title={'Invalide'}
                                />
                        }
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.URL'),
            type: ColumnType.STRING,
            accessor: 'url',
            cell: (row: StandaloneLink, value: any) => {
                let u = value
                try {
                    let url = new URL(value)
                    u = url.hostname
                } catch (e) {
                    console.error(e)
                }

                return (
                    <div
                        title={value}
                        className={'overflow-hidden underline decoration-dashed cursor-help whitespace-nowrap'}
                    >
                        <a href={value} target={'_blank'} className={'text-sm'}>
                            <i className={'fa-solid fa-link p-1 mr-2 rounded-sm bg-blue-500 text-white '} />
                            {u}
                        </a>
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.TARGET_URL'),
            type: ColumnType.STRING,
            accessor: 'targetUrl',
            cell: (row: StandaloneLink, value: any) => {
                let u = value
                try {
                    let url = new URL(value)
                    u = url.pathname
                } catch (e) {
                    console.error(e)
                }

                return (
                    <div
                        title={value}
                        className={'overflow-hidden underline decoration-dashed cursor-help whitespace-nowrap'}
                    >
                        <a href={value} target={'_blank'} className={'text-sm'}>
                            <i className={'fa-solid fa-link p-1 mr-2 rounded-sm bg-blue-500 text-white '} />
                            {u}
                        </a>
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.TARGET_CONTENT'),
            type: ColumnType.STRING,
            accessor: 'targetContent',
            cell: (row: StandaloneLink, value: any) => {
                return (
                    <div title={value}
                         className={'overflow-hidden underline decoration-dashed cursor-help whitespace-nowrap'}>
                        {value}
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.LAST_TYPE'),
            type: ColumnType.STRING,
            accessor: 'lastType',
            cell: (row: StandaloneLink, value: any) => {
                let label = '-'
                if (value === LastType.TXT) {
                    label = 'Texte'
                }
                if (value === LastType.IMG) {
                    label = 'Image'
                }
                return (
                    <div>{label}</div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.TARGET_EXPRESSION'),
            type: ColumnType.STRING,
            accessor: 'targetExpression',
            cell: (row: StandaloneLink, value: any) => {
                return (
                    <div title={value}
                         className={'overflow-hidden underline decoration-dashed cursor-help whitespace-nowrap'}>
                        {value}
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.TARGET_REGEX'),
            type: ColumnType.STRING,
            accessor: 'targetRegex',
            cell: (row: StandaloneLink, value: any) => {
                return (
                    <div title={value} className={'overflow-hidden underline decoration-dashed cursor-help whitespace-nowrap'}>
                        {value}
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.PROVIDER_NAME'),
            type: ColumnType.STRING,
            accessor: 'providerName',
            cell: (row: StandaloneLink) => {
                let url = ''
                if (row.providerName === 'Direct') {
                    return (
                        <div className={''}>
                            <i className={'fa-solid fa-file-invoice-dollar text-2xl'}
                               title={row.providerName}/>
                        </div>
                    )
                }
                if (row.providerName === 'Direct (payé par le client)') {
                    return (
                        <div className={''}>
                            <i className={'fa-solid fa-user text-2xl'} title={row.providerName}/>
                        </div>
                    )
                }
                if (row.providerName === 'Aucun') {
                    return (
                        <div className={''}>
                            <i className={'fa-solid fa-question text-2xl'} title={row.providerName}/>
                        </div>
                    )
                }
                if (row.providerName === 'Rocketlinks') {
                    url = 'https://www.rocketlinks.com/hubfs/favicon-32x32.png'
                }
                if (row.providerName === 'Getfluence') {
                    url = 'https://app.getfluence.com/favicon.ico'
                }
                if (row.providerName === 'Soumettre') {
                    url = 'https://soumettre.fr/favicon-32x32.png'
                }
                if (row.providerName === 'VIP') {
                    url = 'https://vipseo.fr/favicon.png'
                }
                if (row.providerName === 'ViPSEO') {
                    url = 'https://vipseo.fr/favicon.png'
                }
                if (row.providerName === 'Digimood') {
                    url = 'https://www.digimood.com/wp-content/uploads/2020/06/logo-digimood-favicon-2020.png'
                }
                return (
                    <div>
                        <img
                            src={url}
                            className={'w-6 h-6'}
                            title={row.providerName}
                            alt={row.providerName}
                        />
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.LANGUAGE_NAME'),
            type: ColumnType.STRING,
            accessor: 'languageName'
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.CUSTOMER_NAME'),
            type: ColumnType.STRING,
            accessor: 'customerName',
            cell: (row: StandaloneLink, value: any) => {
                if (!row.quoteId) {
                    return value
                }

                return (
                    <Link
                        to={`/dashboard/netlinking/customer/${row.customerId}/`}
                    >
                        {value}
                    </Link>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.QUOTE_REF'),
            type: ColumnType.STRING,
            accessor: 'quoteRef',
            cell: (row: StandaloneLink, value: any) => {
                if (!row.quoteId) {
                    return getTranslation('NETLINKING.VIEW.LINK.TABLE.QUOTE_N/A')
                }

                return (
                    <div title={value} className={'overflow-hidden whitespace-nowrap'}>
                        <Link
                            to={`/dashboard/netlinking/quote/${row.quoteId}/`}
                        >
                            {value}
                        </Link>
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.INVOICE_STATUS'),
            type: ColumnType.STRING,
            accessor: 'invoiceStatus',
            cell: (row: StandaloneLink, value: any) => {
                return (
                    <div>
                        {
                            getTranslation(`INVOICE_STATUS.${value}.LABEL`)
                        }
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.LINK.TABLE.PRICE_HT'),
            type: ColumnType.NUMBER,
            accessor: 'priceHt',
            cell: (row: StandaloneLink, value: any) => {
                if(value) {
                    return value.toLocaleString(
                        defaultLanguage,
                        {
                            style: 'currency',
                            currency: 'EUR'
                        }
                    )
                }
                return '-'
            }
        }
    ]

    let onClickExport = () => {
        if (props.links.length > 0) {
            ExportApi.csv(props.token, props.links)
        }
    }

    let formatter = (links: Backlink[]) => {
        return links.map((link) => {
            return StandaloneLinkFactory.fromLink(
                link,
                props.cacheCustomers,
                props.cacheProviders,
                props.cacheAccounts,
                props.cacheQuotes,
            )
        })
    }

    let onSelectRows = (rows: any) => {
        setSelectedRows(rows)
    }

    let onToggleValid = (state: 'all' | 'valid' | 'issues' | 'broken' | 'underCorrection') => {
        setToggleState(state)
        onResetRows()

        if (props.onToggleValid) {
            props.onToggleValid(state)
        }
    }

    let onResetRows = () => {
        setResetRows(true)
        let intervalId = setInterval(() => {
            setResetRows(true)
        }, 500)

        setTimeout(() => {
            clearInterval(intervalId)
            setResetRows(false)
        }, 500)

        setSelectedRows([])
    }

    let onUpdateLinks = () => {
        if (props.onToggleValid) {
            props.onToggleValid(toggleState)
            onResetRows()
        }
    }


    return (
        <div className={'relative w-full mt-4 bg-white p-8 rounded-lg shadow-sm'}>
            <div className={'w-full'}>
                <MainLinkCard
                    links={rows}
                    columns={showColumns}
                    cacheCustomers={props.cacheCustomers}
                    cacheProviders={props.cacheProviders}
                    cacheAccounts={props.cacheAccounts}
                    cacheQuotes={props.cacheQuotes}
                />

                <div className={'w-full overflow-x-auto'}>
                    <div className={'w-full my-6 flex items-center justify-between'}>
                        <ButtonGroup
                            buttons={[
                                {
                                    text: 'Liens',
                                    icon: 'fa-solid fa-link',
                                    onClick: () => onToggleValid('all')
                                },
                                {
                                    text: 'Valides',
                                    icon: 'fa-solid fa-circle-check text-green-500',
                                    onClick: () => onToggleValid('valid')
                                },
                                {
                                    text: 'Problématiques',
                                    icon: 'fa-solid fa-triangle-exclamation text-yellow-400',
                                    onClick: () => onToggleValid('issues')
                                },
                                {
                                    text: 'Attente éditeur',
                                    icon: 'fa-solid fa-hourglass-half text-orange-500',
                                    onClick: () => onToggleValid('underCorrection')
                                },
                                {
                                    text: 'Cassés',
                                    icon: 'fa-solid fa-circle-xmark text-red-500',
                                    onClick: () => onToggleValid('broken')
                                },
                            ]}
                        />

                        <div className={'w-full lg:w-1/2 flex flex-col gap-y-3 sm:flex-row sm:justify-end sm:items-end gap-x-3'}>
                            <LinkBulkOperations
                                token={props.token}
                                links={selectedRows}
                                cacheUpdater={props.cacheUpdater}
                                onUpdateLinks={onUpdateLinks}
                            />

                            <CheckAnalysisLinks
                                token={props.token}
                                cacheUpdater={props.cacheUpdater}
                                onUpdateLinks={onUpdateLinks}
                            />
                        </div>
                    </div>

                    <FullTable
                        rows={props.links}
                        formatter={formatter}
                        columns={defaultColumns}
                        showColumns={props.columns}
                        localStorageColumns={'bc-link-table'}
                        onClickExport={onClickExport}
                        onChange={(rows: StandaloneLink[], columns: (Paths<StandaloneLink>)[]) => {
                            setRows(rows)
                            setShowColumns(columns)
                        }}
                        labelExport={getTranslation('NETLINKING.VIEW.LINK.TABLE.EXPORT')}
                        infoMessage={getTranslation('NETLINKING.VIEW.LINK.TABLE.INFO_MESSAGE')}
                        labelOptionAll={getTranslation('NETLINKING.VIEW.LINK.TABLE.ALL')}
                        options={[
                            {
                                value: '$', label: '[Finance]', group: [
                                    'key',
                                    'period',
                                    'priceHt',
                                    'url',
                                    'userEmail',
                                    'customerName',
                                    'quoteRef',
                                    'invoiceStatus',
                                    'providerName',
                                ]
                            },
                            {
                                value: '$', label: '[Consultant SEO]', group: [
                                    'key',
                                    'period',
                                    'customerName',
                                    'providerName',
                                    'languageName',
                                    'targetContent',
                                    'targetExpression',
                                    'targetUrl',
                                    'isIndexed',
                                    'isValid',
                                ]
                            }
                        ]}
                    >
                        <PaginateAdvancedTable
                            onSelectRows={onSelectRows}
                            onResetRows={resetRows}
                            translator={translator}
                            mobileColumnsDisplayed={[0, 1, 2]}
                        />
                    </FullTable>
                </div>
            </div>
        </div>
    )

}